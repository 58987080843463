<template lang="pug">
  paginate(
    v-if="pageCount > 1"
    v-model="page"
    :page-count="pageCount"
    :click-handler="changePage"
    :first-last-button="true"
    first-button-text="<i class='fa fa-angle-double-left'>"
    last-button-text="<i class='fa fa-angle-double-right'>"
    prev-text="<i class='fa fa-angle-left'>"
    next-text="<i class='fa fa-angle-right'>"
    prev-link-class="nav"
    next-link-class="nav"
    disabled-class="pagination-disabled-class"
    container-class="ui-pagination")
</template>

<script>
export default {
  name: 'UiPagination',

  props: {
    value: Number,
    pageCount: Number
  },

  mounted () {
    this.page = this.value
  },

  data: () => ({
    page: 1
  }),

  methods: {
    changePage (page) {
      this.$emit('input', page)
      this.$emit('on-page-change', page)
    }
  },

  watch: {
    value (value) {
      this.page = value
    }
  }
}
</script>

<style lang="scss">
  .ui-pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;

    li {
      height: 30px;
      width: 30px;
      list-style: none;
      margin: 8px;
      font-size: 13px;

      a {
        height: 30px;
        width: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.active {
        border-radius: 4px;
        background: $color-primary;

        a {
          color: $color-white;
        }
      }

      .nav,
      &:first-child,
      &:last-child {
        background: $light-blue;
        border-radius: 4px;

        &:hover {
          color: $color-white;
          background: $color-primary;

          a {
            color: $color-white;
          }
        }
      }
    }
  }

  .pagination-disabled-class{
    .nav{
     color: grey !important;
     background: #f8f8f9 !important;
     cursor: default;
    }

    &:first-child,
    &:last-child{
      background: #f8f8f9 !important;

      a{
        color: grey !important;
        cursor: default;
      }
    }
  }

  @media screen and (max-width: 780px) {
    .ui-pagination {
      li:first-child,
      li:last-child {
        display: none;
      }
    }
  }
</style>
