<template lang="pug">
  v-card
    v-scrollable(
      :bottom-offset="105")
      v-card-head
        v-card-head-label
          h2.title {{ 'ui.labels.employees' | translate}}
          p.subtitle {{ 'infotext.here_you_can_manage_employees' | translate }}
        v-card-head-toolbar
          v-input-search(
            :placeholder="'pages.administration.filter_by_name_or_e_mail' | translate"
            type="text"
            v-model="search"
          )
      v-card-content.v-card-right-padding
        .text-right
          button.btn.btn-clean-hover-primary.icon-btn(
              @click="openExport")
              i.fa.fa-file-export
              | {{ 'pages.administration.export_employees' | translate}}
          div(v-id="!isThirdPartyLoginEnabled" style="display: inline-block")
            input(style="display: none" type='file' accept=".csv" ref="uploadCsv" @input='onFileUpload($event)')
            button.btn.btn-clean-hover-primary.icon-btn(
              @click="openInput")
              i.fa.fa-upload
              | {{ 'pages.administration.upload_from_csv' | translate}}
            button.btn.btn-clean-hover-primary.icon-btn(
              @click="openCreateView")
              i.fa.fa-plus
              | {{ 'pages.administration.add_new_employee' | translate }}

        v-table.table-fixed
          v-table-head
            th(style="width:50px")
              v-checkbox(@input="onChangeAllChecked" :value="isAllChecked")
            th.table-sort-arrows-hover(
              @click="onSortEmployees('firstName', sortedLabels.firstName)")
              | {{ 'ui.labels.first_name' | translate }}
              v-table-sorter(
                :show="currentSortLabel === 'firstName'"
                :toggled="sortedLabels.firstName")
            th.table-sort-arrows-hover(
              @click="onSortEmployees('lastName', sortedLabels.lastName)")
              | {{ 'ui.labels.last_name' | translate }}
              v-table-sorter(
                :show="currentSortLabel === 'lastName'"
                :toggled="sortedLabels.lastName")
            th.table-sort-arrows-hover(
              @click="onSortEmployees('email', sortedLabels.email)")
              | {{ 'ui.labels.e_mail' | translate }}
              v-table-sorter(
                :show="currentSortLabel === 'email'"
                :toggled="sortedLabels.email")
            th.table-sort-arrows-hover(
              @click="onSortEmployees('position', sortedLabels.position)")
              | {{ 'ui.labels.position' | translate }}
              v-table-sorter(
                :show="currentSortLabel === 'position'"
                :toggled="sortedLabels.position")
            th.table-sort-arrows-hover(
              @click="onSortEmployees('department', sortedLabels.department)")
              | {{ 'ui.labels.department' | translate }}
              v-table-sorter(
                :show="currentSortLabel === 'department'"
                :toggled="sortedLabels.department")
            th.table-sort-arrows-hover(
              @click="onSortEmployees('location', sortedLabels.location)")
              | {{ 'ui.labels.location' | translate }}
              v-table-sorter(
                :show="currentSortLabel === 'location'"
                :toggled="sortedLabels.location")
            th.table-sort-arrows-hover(
              @click="onSortEmployees('isActive', sortedLabels.isActive)"
              v-tooltip="$t('infotext.allow_user_to_log_in')"
              style="width:60px") {{ 'pages.administration.active' | translate }}
              v-table-sorter(
                :show="currentSortLabel === 'isActive'"
                :toggled="sortedLabels.isActive")
            th(:style="actionColStyles")

          v-table-body(
            :cols="9"
            :empty="!employees.length"
            :loaded="loaded"
            :no-result="!employees.length && !!search.length")
            v-table-row(
              v-for="(item, index) in employees"
              :key="index")
              td
                v-checkbox(:value="item.checked" @input="onChangeCheckedEmployee(item)")
              td
                .flex.align-center
                  v-avatar.mr-3(
                    :size="30"
                    :src="item.profilePictureUrl")
                  span.strong {{ item.firstName }}
              td.strong {{ item.lastName }}
              td(v-tooltip="item.email") {{ item.email }}
              td(v-tooltip="showLabelTooltip(item, 'position')") {{ showLabelTitle(item, 'position') | getNameField }}
              td(v-tooltip="showLabelTooltip(item, 'department')") {{ showLabelTitle(item, 'department') | getNameField }}
              td(v-tooltip="item.location ? item.location.name : '' ") {{ item.location || {} | getNameField }}
              td
                v-switch(
                  icon
                  outline
                  small
                  no-margin
                  :value="!!item.isActive"
                  :disabled="isThirdPartyLoginEnabled"
                  @input="toggleEmployee(item)")
              //- td
              //-   v-btn(
              //-     icon
              //-     @click="openSkillProfile(item)"
              //-     v-tooltip="$t('View skill profile')")
              //-     i.fa.fa-pencil-alt
              td.text-right
                .flex
                  v-btn(
                    icon
                    @click="openSkillProfile(item)"
                    v-tooltip="$t('View skill profile')")
                    i.fa.fa-eye
                  v-btn(
                    icon
                    @click="openEditView(item)"
                    v-tooltip="$t('infotext.edit')")
                    i.fa.fa-pencil-alt
                  v-btn(
                    v-if="!isThirdPartyLoginEnabled"
                    icon
                    danger
                    @click="onItemDelete(item)"
                    v-tooltip="$t('infotext.remove')")
                    i.fa.fa-trash-alt
    .action-wrapper
      .action-wrapper--actions
        span.action-wrapper--selected-count {{ selectedCountMessage }}
        v-action-selector(:disabled="!selectedCount"
          :options="actionOptions"
          :selectedActionOptions="actionData.selectedActionOptions"
          :selectedOption="actionData.selectedOption"
          :selectedActionOption="actionData.selectedActionOption"
          :loading="loadingMultipleAction"
          @on-change-action-option="onChooseSelectedAction"
          @on-change-option="onChooseAction"
          @on-apply="onApplySeletion")
      .action-wrapper--pagination
        v-pagination(:initialPage="currentPage" :totalCount="paginationParams.totalCount" :perPage="paginationParams.perPage"
          @on-page-change="onPageChange")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { MODAL_TYPES } from '@/util/constants'

import UiPagination from '@/components/ui/Pagination'

export default {
  name: 'Employees',

  components: {
    UiPagination
  },

  async mounted () {
    const sortBy = 'lastName'
    const params = {
      sort: sortBy
    }
    this.sortedLabels[sortBy] = true
    this.currentSortLabel = sortBy
    this.currentSortParams = JSON.parse(JSON.stringify(params, {}))
    await this.loadEmployees({ isPushed: false, params })
    this.loaded = true

    if (!this.positions.length) {
      this.loadPositions()
    }

    if (!this.locations.length) {
      this.loadLocations()
    }
  },

  data: (vm) => ({
    isAllChecked: false,
    search: '',
    loaded: false,
    sortedLabels: {
      firstName: false,
      lastName: false,
      email: false,
      position: false,
      department: false,
      location: false,
      isActive: false
    },
    currentSortLabel: null,
    currentPage: 1,
    currentSortParams: {},
    actionData: {
      selectedOption: {},
      selectedActionOption: {},
      selectedActionOptions: []
    },
    loadingMultipleAction: false,
    actionOptions: [
      { id: 'remove', name: vm.$t('ui.buttons.remove'), show: true },
      { id: 'make_status_active', name: vm.$t('ui.buttons.make_active'), show: true },
      { id: 'make_status_inactive', name: vm.$t('ui.buttons.make_inactive'), show: true },
      { id: 'set_position', name: vm.$t('ui.buttons.set_position'), labelKey: 'positions' },
      { id: 'set_location', name: vm.$t('ui.buttons.set_location'), labelKey: 'locations' }
    ]
  }),

  filters: {
    getNameField (item) {
      return item ? item.name : '-'
    }
  },

  methods: {
    ...mapActions('modals', [
      'openConfirmModal'
    ]),

    ...mapActions('positions', [
      'loadPositions'
    ]),

    ...mapActions('locations', [
      'loadLocations'
    ]),

    ...mapActions('employees', [
      'loadEmployees',
      'searchEmployees',
      'updateEmployee',
      'removeEmployee',
      'removeEmployeesMultiple',
      'activateMultiple',
      'deactivateMultiple',
      'setToPositionMultiple',
      'setToLocationMultiple'
    ]),

    ...mapActions('setupWizard', [
      'setEmployeesCsv'
    ]),

    openSkillProfile (item) {
      this.$router.push(`/employee/${item.id}`)
    },

    onFileUpload (event) {
      const fileArray = Array.from(event.target.files)
      this.setEmployeesCsv(fileArray[0])
      this.$router.push('/administration/employees/import')
      this.$refs.uploadCsv.value = ''
    },

    openCreateView () {
      this.$router.push('/administration/employees/add')
    },

    openEditView (item) {
      this.$router.push(`/administration/employees/${item.id}`)
    },

    openInput () {
      this.$refs.uploadCsv.click()
    },

    openExport () {
      window.open(process.env.VUE_APP_BASE_URL + '/employees/export', '_blank')
    },

    onItemDelete (item) {
      const options = {
        content: {
          title: this.$t('ui.modals.delete_message_with_params', { name: `${item.firstName} ${item.lastName}` })
        },
        props: {
          type: MODAL_TYPES.WARNING_RED
        },
        buttons: {
          apply: {
            label: 'ui.buttons.delete',
            callback: this.removeEmployeeHandler,
            params: item
          }
        }
      }
      this.openConfirmModal(options)
    },

    removeEmployeeHandler (item) {
      this.removeEmployee(item).then(res => {
        this.onPageChange(this.currentPage)
      })
    },

    onSortEmployees (fieldName, order) {
      this.currentPage = 1
      const params = {
        sort: order ? `-${fieldName}` : fieldName
      }
      this.currentSortParams = JSON.parse(JSON.stringify(params, {}))
      if (this.search) {
        params.q = this.search
      }
      this.loadEmployees({ params })
      for (const key in this.sortedLabels) {
        if (key === fieldName) {
          this.sortedLabels[key] = !this.sortedLabels[key]
          this.currentSortLabel = fieldName
        } else {
          this.sortedLabels[key] = false
        }
      }
    },

    toggleEmployee (data) {
      const payload = {
        id: data.id,
        isActive: data.isActive ? 0 : 1
      }
      this.updateEmployee({ payload })
    },

    onPageChange (number) {
      this.currentPage = number
      if (!this.search) {
        const filters = {
          page: number,
          ...this.currentSortParams
        }

        this.loadEmployees({ params: filters })
      } else {
        const filters = {
          q: this.search,
          page: number,
          ...this.currentSortParams
        }
        this.searchEmployees({ params: filters })
      }
    },

    showLabelTooltip (item, key) {
      let show = ''
      if (!item[key]) {
        if (key === 'department' && item['position'] && item['position'].department) {
          show = item['position'].department.name
        }
      } else if (item[key].id !== null && item[key].id !== undefined) {
        show = item[key].name
      }
      return show
    },

    showLabelTitle (item, key) {
      let show = {}
      if (!item[key]) {
        if (key === 'department' && item['position'] && item['position'].department) {
          show = item['position'].department
        }
      } else if (item[key].id !== null && item[key].id !== undefined) {
        show = item[key]
      }

      return show
    },

    onChangeAllChecked (param) {
      this.isAllChecked = !this.isAllChecked
      if (this.isAllChecked) {
        this.employees.forEach(el => { el.checked = true })
      } else {
        this.employees.forEach(el => { el.checked = false })
      }
    },

    onChangeCheckedEmployee (item) {
      item.checked = !item.checked
      if (!item.checked) {
        this.isAllChecked = false
      } else if (!this.employees.find(el => !el.checked)) {
        this.isAllChecked = true
      }
    },

    onChooseAction (value) {
      this.actionData.selectedOption = value
      if (value.labelKey) {
        this.actionData.selectedActionOptions = this[value.labelKey]
      } else {
        this.actionData.selectedActionOptions = []
      }
    },

    onChooseSelectedAction (value) {
      this.actionData.selectedActionOption = value
    },

    async onApplySeletion () {
      let employees = this.employees.filter(el => el.checked)
      this.loadingMultipleAction = true
      if (this.actionData.selectedOption.id === 'remove') {
        await this.removeEmployeesMultiple({ employees }).then(() => {
          this.onPageChange(this.currentPage)
        })
      } else if (this.actionData.selectedOption.id === 'make_status_active') {
        await this.activateMultiple({ employees })
      } else if (this.actionData.selectedOption.id === 'make_status_inactive') {
        await this.deactivateMultiple({ employees })
      } else if (this.actionData.selectedOption.id === 'set_position') {
        await this.setToPositionMultiple({ position: this.actionData.selectedActionOption, employees })
      } else if (this.actionData.selectedOption.id === 'set_location') {
        await this.setToLocationMultiple({ location: this.actionData.selectedActionOption, employees })
      }

      this.loadingMultipleAction = false
    }
  },

  computed: {
    ...mapGetters('employees', [
      'employees',
      'paginationParams'
    ]),

    ...mapGetters('positions', [
      'positions'
    ]),

    ...mapGetters('locations', [
      'locations'
    ]),

    ...mapGetters('app', [
      'isThirdPartyLoginEnabled'
    ]),

    actionColStyles () {
      return this.isThirdPartyLoginEnabled ? 'width:50px' : 'width:100px'
    },

    selectedCount () {
      return this.employees.filter(el => el.checked).length
    },

    selectedCountMessage () {
      return this.$t('pages.administration.users_selected', {
        count: this.selectedCount
      })
    }

  },

  watch: {
    async search (value) {
      this.loaded = false
      this.currentPage = 1
      await this.searchEmployees({ params: { q: value, ...this.currentSortParams }, isPushed: false })
      this.loaded = true
    },

    employees (value) {
      this.isAllChecked = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 2rem 2rem 2rem;

    &--selected-count {
      white-space: nowrap;
      padding-right: 1rem;
    }

    &--pagination {
      padding-left: 1rem;
    }

    &--actions {
      display: flex;
      align-items: center;
    }
  }

  .form-control {
    position: relative;
  }
  .v-card__head-toolbar {
    position: relative;
    height: fit-content;

    .icon-input-clear {
      right: 5px
    }
  }
</style>
